import { Tab } from './tab';

/**
* Initialise tout les éléments Tab dans la page
*/
const build = () => {
  Tab.build(document);
};

export { build };
